<template>
    <div>
    </div>
</template>

<script>
    import jwt_decode from "jwt-decode";
    const de = require('@/utils/lib1.js')
    import enc from '@/utils/enc.js'
    export default {
        data() {
            return {
                token: '',
                deviceId: '',
                classnum: '',
                JoinGroupStatus: '0',
                pageParam: '',
                loginBack: {
                    ticket: '',
                    name: '',
                    teamModelId: ''
                },
                messagetext: '正在<b>登录中</b>......<br/><span class="text-primary">请等待...</span>',
                //新用户系统参数
                backdata: {
                    backCode: '',
                    backClientId: '',
                },
                blobConnect: {
                    name: '',
                    sas: '',
                    url: '',
                    id:''
                },
            }
        },
        created() {
            this.showLoading(this.messagetext)
        },
        mounted() {
            this.loginCallback()

        },
        methods: {
            showLoading(msgss) {
                this.$q.loading.show({
                    message: msgss
                })
            },
            // 获取基础信息以及token
            loginInfo() {
                let that = this
                let deviceId = this.common.guid()
                that.$api.getLoginInfo(
                    {
                        'jsonrpc': '2.0',
                        'method': 'Regist',
                        'params': {
                            'deviceId': deviceId,
                            'clientId': '931dee8c-74be-4c9b-a602-c74583b0e985',
                            'verificationCode': 'cc13f9c6-54a1-4ce9-a3d9-8952c335983e',
                            'verificationCodeVer': '46662dcbd6a6421f9d45d1857ebdec95',
                            'productCode': 'LZLL6ZEI',
                            'extraInfo': {}
                        },
                        'id': 1
                    }
                ).then(res => {
                    sessionStorage.setItem('deviceId', deviceId)
                    that.tokenRes = res.result
                    that.token = res.result.token
                    if (that.token) {
                        sessionStorage.setItem('TWtoken', that.token)
                        sessionStorage.setItem('serviceUrl', JSON.stringify(res.result.serviceUrl))
                        this.messagetext = '正在<b>初始化</b>......<br/><span class="text-primary">请等待...</span>';
                        that.deviceId = deviceId
                        this.CreateGroup()
                    } else {
                        that.$Message.warning('服务器错误！未返回token')
                    }
                }).catch(res => {

                })
            },
            // 处理返回信息 得到信息
            getParamAsCH(paramName) {
                var paramValue = ''
                var isFound = false
                if (window.location.hash.indexOf('?') == 10 && window.location.hash.indexOf('=') > 1) {
                    var arrSource = decodeURI(window.location.hash).substring(1, window.location.hash.length).split('&')
                    var i = 0
                    while (i < arrSource.length && !isFound) {
                        if (arrSource[i].indexOf('=') > 0) {
                            if (arrSource[i].split('=')[0].toLowerCase() == paramName.toLowerCase()) {
                                paramValue = arrSource[i].split('=')[1]
                                isFound = true
                            }
                        }
                        i++
                    }
                }
                paramValue = unescape(paramValue)
                return paramValue
            },
            // 回调处理
            loginCallback() {
                let that = this
                console.log(window.location.href, '当前的url')
                var nowUrl = window.location.href
                console.log(nowUrl, '返回的ID')
                if (nowUrl.indexOf('code') !== -1 && nowUrl.indexOf('clientId') !== -1) {

                    var code = nowUrl.substring(nowUrl.indexOf('code') + 5, nowUrl.indexOf('clientId')-1)
                    var clientId = nowUrl.substring(nowUrl.indexOf('clientId') + 9, nowUrl.indexOf('state') - 1)
                    this.backdata.backCode = code
                    this.backdata.backClientId = clientId
                }
                this.authandToken(this.backdata.backCode, this.backdata.backClientId)
                sessionStorage.setItem('loginBack', JSON.stringify(this.backdata))
                
            },
            // 创建教室
            CreateGroup() { // 创建教室
                let that = this
                let deviceId = that.deviceId
                that.$api.getLoginInfo(
                    {
                        'jsonrpc': '2.0',
                        'method': 'CreateGroup',
                        'params': {
                            'deviceId': deviceId,
                            'doBoundGroupNum': false,
                            'extraInfo': {}
                        },
                        'id': 1
                    }
                ).then(res => {
                    that.messagetext = '正在<b>创建教室</b>......<br/><span class="text-primary">请等待...</span>';
                    let result = res.result
                    sessionStorage.setItem('classNum', result.groupNum)
                    that.classnum = result.groupNum
                    that.JoinGroup()
                }).catch(res => {

                    this.$router.push('/')
                    // that.$Message.success("创建教室失败，请重试！");
                })
            },
            // 加入教室
            JoinGroup() { // 加入教室
                let that = this
                that.messagetext = '正在<b>加入教室</b>......<br/><span class="text-primary">请等待...</span>';
                let deviceId = sessionStorage.getItem('deviceId')
                that.$api.getLoginInfo(
                    {
                        'jsonrpc': '2.0',
                        'method': 'JoinGroup',
                        'params': {
                            'deviceId': deviceId,
                            'groupNum': sessionStorage.getItem('classNum')
                        },
                        'id': 1
                    }
                ).then(res => {
                    let result = res.result
                    console.log(result,'返回')
                    that.pageParam = res.result.mqtt
                    sessionStorage.setItem('mqtts', JSON.stringify(res.result))
                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 8000)
                    that.disposeIdtoken()
                    /*this.$router.push('/Board');*/
                }).catch(res => {
                    this.$router.push('/')
                    that.$Message.success('加入教室失败，请重试！')
                })
            },
            //获取上传文件的idtoken和用户信息
            disposeIdtoken() {
                var that = this
                var tokens = sessionStorage.getItem('TWtoken')
                console.log(that.loginBack.ticket,'查看数据')
                that.$api.getIdtoken(
                    {
                        'ticket': that.loginBack.ticket,
                        'token': tokens,
                    }
                ).then(res => {
                    console.log(res, '查看返回的IDtoken')
                    sessionStorage.setItem('idToken', JSON.stringify(res.idToken))
                })
            },
            //new OAuth整合ID
            authandToken(code,clientid) {
                this.$api.authorize(
                    {
                        'grant_type': "authorization_code",
                        'client_id': clientid,
                        'code': code
                    }
                ).then(res => {
                    console.log(res, '成功')
                    this.messagetext = '正在<b>初始化</b>......<br/><span class="text-primary">请等待...</span>';
                    sessionStorage.setItem('idToken', res.id_token)
                    sessionStorage.setItem('access_token', res.access_token)
                    sessionStorage.setItem('expires_in', res.expires_in)
                    sessionStorage.setItem('token_type', res.token_type)
                    var jwtUser = jwt_decode(res.id_token)
                    this.blobConnect.id = jwtUser.sub
                    sessionStorage.setItem('user', JSON.stringify(jwtUser))
                    this.blobAuth()
                }).catch(res => {
                    console.log(res, '失败')
                })
            },
            //new blob-auth
            blobAuth() {
                this.$api.blobauthFile().then(res => {
                    this.messagetext = '<b>登录成功！</b>......<br/><span class="text-primary">请等待...</span>';
                    console.log(res, '成功blob')
                    this.blobConnect.name = res.name
                    this.blobConnect.sas = res.sas
                    this.blobConnect.url = res.url
                    sessionStorage.setItem('blobConnect', enc.encrypt(JSON.stringify(this.blobConnect), de.text))
                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 5000)
                    this.$router.push('/Board');
                }).catch(res => {
                    console.log(res, '失败blob')
                })
            }
        },
        watch: {
            messagetext(a) {
                this.showLoading(a);
            }
        },
        beforeDestroy() {
            if (this.timer !== void 0) {
                clearTimeout(this.timer)
                this.$q.loading.hide()
            }
        }
    }
</script>
